import React from 'react';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import GroupIcon from '@material-ui/icons/Group';
import AssessmentIcon from '@material-ui/icons/Assessment';
import CropFreeIcon from '@material-ui/icons/CropFree';


function getIconByName(iconName) {
	switch (iconName) {
		case 'LocalMallIcon':
			return <LocalMallIcon />;
		case 'LibraryBooksIcon':
			return <LibraryBooksIcon />;
		case 'GroupIcon':
			return <GroupIcon />;
		case 'AssessmentIcon':
			return <AssessmentIcon />;
		case 'CropFreeIcon':
			return <CropFreeIcon />;
		default:
			console.warn(`Unknown Icon ${iconName}`)
			break;
	}
}

export default getIconByName;