'use strict';

import { green, red } from '@material-ui/core/colors';

const styles = theme => ({
	deleteButton: {
		marginLeft: '10px',
		color: red[500],
		borderColor: red[500]
	},
	removeButton: {
		color: red[500],
	},
	addButton: {
		color: green[500],
	}
});

export default styles;