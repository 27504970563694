import RestAPI, { Status } from 'context/RestAPI';
import getIconByName from 'components/Icons';
import { ActionTask } from 'components/Actions';
import Attribute, { AttributeType } from 'models/Attributes.js';
import ApplicationObject from 'models/ApplicationObject.js';
import CodeConfig from './codes.yaml';


class ApplicationObjects {
    constructor(objectsName, objectName, title, subtitle, iconName, actionTypes, 
                objectIdentifier, endpoints, searchbarPlaceholder, attributesMask, page) {
        this.objectsName = objectsName;   
        this.objectName = objectName;
        this.title = title;
        this.subtitle = subtitle;
        this.icon = getIconByName(iconName);
        this.actions = actionTypes;
        this.objectIdentifier = objectIdentifier;
        this.endpoints = endpoints;
        this.searchbarPlaceholder = searchbarPlaceholder;
        this.attributesMask = attributesMask;
        this.page = page;
        this.status = Status.Init;
        this.list = [];
        this._onChange = null;
    }

    setOnChange = (onChange) => {
        this._onChange = onChange;
    }

    getObjectById = (objectId) => {
        return this.list.find(obj => obj.id == objectId);
    }

    getActions = (onCopy, toCodes, showDetails) => {
        const actions = this.actions.map((action) => {
            switch (action.type) {
                case 'copy':
                    return { type: ActionTask.Copy, onClick: onCopy(action.data) };
                case 'toCodes':
                    return { type: ActionTask.ToCodes, onClick: toCodes };
                case 'showDetails':
                    return { type: ActionTask.Details, onClick: showDetails }
            }
        });
        return actions;
    }

    updateObjects = async (authData, accountId) => {
        this.status = Status.Loading;
        return RestAPI.get(this.endpoints.get, authData, this._generateAttributes, accountId).then(
            status => {
                this.status = status;
                if (this._onChange) { this._onChange(); }
            }
        );
    }

    updateObject = async (authData, applicationObject, attribute, newValue, operation) => {
        const updateValue = this._constructUpdateValue(applicationObject, attribute, newValue);
        const body = { operation, type: attribute.path.split('.')[0], object: updateValue };
        const urlParams = {[this.objectIdentifier]: applicationObject.id}
        return await RestAPI.post(this.endpoints.update, authData, body, null, urlParams).then(
            ({status, data}) => { 
                // Update Object in this list of Objects
                if (status == Status.Success) {
                    this.list = this.list.map((applicationObject) => {
                        if (applicationObject.id == data[this.objectIdentifier]) {
                            const alternative_ids = this.list.map(obj => obj.id);
                            return this._parseObject(data, alternative_ids);
                        }
                        return applicationObject;
                    });
                }
                if (this._onChange) { this._onChange(); }
                return {status, data};
            });
    }

    createObject = async (authData, accountId, inputData) => {
        // Create default object
        let newObject = { account: accountId };
        this.attributesMask.map(mask => {
            if (mask.createDefault) {
                newObject[mask.path] = mask.createDefault;
            }
        });

        // Fetch with new data
        for (let key of Object.keys(inputData)) {
            if ((key.split('.').length == 1)) {
                newObject[key] = inputData[key];
                continue;
            }
            if ((key.split('.').length == 2)) {
                if (Object.keys(newObject).indexOf(key.split('.')[0]) > -1) {
                    newObject[key.split('.')[0]][key.split('.')[1]] = inputData[key];
                    continue;
                } 
                newObject[key.split('.')[0]] = {[key.split('.')[1]]: inputData[key]};
                continue;
            }
            console.warn("Can't fetch data since an object depth >2 is not implemented.");
        }

        return await RestAPI.post(this.endpoints.create, authData, newObject, null).then(
            ({status, data, error }) => { 
                // Update Object in this list of Objects
                if (status == Status.Success) {
                    const alternative_ids = this.list.map(obj => obj.id);
                    const newApplicationObject = this._parseObject(data, alternative_ids);
                    this.list.push(newApplicationObject);
                    if (this._onChange) { this._onChange(); }
                    return newApplicationObject;
                } 
                throw {status, error}
            }
        );
    }

    _generateAttributes = (objectsData) => {
        const alternative_ids = objectsData.map(obj => obj[this.objectIdentifier]);
        this.list = objectsData.map(obj => this._parseObject(obj, alternative_ids));
    }

    _parseObject = (objectData, alternative_ids) => {
        let attributes = [];
        for (const mask of this.attributesMask) {
            const type = AttributeType[mask.type];
            const value = this._parseValue(objectData, mask.path);
            let options = null;
            if (mask.options) {
                if (Array.isArray(mask.options)) {
                    options = mask.options.filter(option => (value.indexOf(option) == -1));
                }
                if (mask.options == 'alternatives') {
                    options = alternative_ids.filter(id => {
                        return (id != objectData[this.objectIdentifier]) && (value.indexOf(id) == -1);
                    });
                }
            } 
            attributes.push(
                new Attribute(mask.name, mask.path, value, type, mask.update, mask.visible, options, mask.applicationObject)
            );
        }
        const title = this._parseValue(objectData, this.title);
        const subtitle = this._parseValue(objectData, this.subtitle);
        return new ApplicationObject(this.objectName, title, subtitle, this.icon, 
            objectData[this.objectIdentifier], attributes, objectData);
    }

    _parseValue(data, path) {
        let sub_data = data;
        for (let param of path.split('.')) {
            sub_data = sub_data[param];
        }
        return sub_data   ;
    }

    _constructUpdateValue(applicationObject, attribute, newValue) {
        if (attribute.path.split('.').length == 1) {
            return newValue;
        } else if (attribute.path.split('.').length == 2) {
            let object = Object.assign({}, applicationObject._rawData[attribute.path.split('.')[0]]);
            object[attribute.path.split('.')[1]] = newValue;
            return object;
        }
        throw new Error('Not implementend for deeper paths');
    }


    filter = (filterFnc) => {
        return this.list.filter(filterFnc);
    }
}

class CodeObjects extends ApplicationObjects {
    constructor() {
        super(CodeConfig.objectsName, CodeConfig.objectName, CodeConfig.title, CodeConfig.subtitle, 
            CodeConfig.iconName, CodeConfig.actionTypes, CodeConfig.objectIdentifier, CodeConfig.endpoints, 
            CodeConfig.searchbarPlaceholder, CodeConfig.attributesMask, CodeConfig.page);
    }
}

export default ApplicationObjects;
export { CodeObjects }