import React, { createContext } from 'react';
import offline_rome from './offline_rome.yaml';
import ApplicationObjects from 'models/ApplicationObjects.js';


const defaultState = {
    apps: {},
    applicationKeys: [],
    updateApplications: () => {}
}

function loadApplications(applicationsMask) {
    let apps = {};
    const applicationNames = Object.keys(applicationsMask);
    for (const appName of applicationNames) {
        apps[appName] = { title: applicationsMask[appName].title, 
                          subscription: applicationsMask[appName].subscription,
                          applicationObjects: [] };
        for (const objectsMask of applicationsMask[appName].applicationObjects) {
            const applicationObject = new ApplicationObjects(objectsMask.objectsName, objectsMask.objectName, 
                objectsMask.title, objectsMask.subtitle, objectsMask.iconName, 
                objectsMask.actionTypes, objectsMask.objectIdentifier, objectsMask.endpoints, 
                objectsMask.searchbarPlaceholder, objectsMask.attributesMask, objectsMask.page);
                apps[appName].applicationObjects.push(applicationObject);
        }
    }
    return apps;
}


const ApplicationsContext = createContext(defaultState);

class ApplicationsProvider extends React.Component {

    state = {
        apps: {},
        applicationKeys: [],
        ready: false
    }

    updateReadyState = (ready) => {
        this.setState({ apps: this.state.apps, applicationKeys: this.state.applicationKeys, ready });
    }
    
    updateApplications = async () => {
        // ToDo: Replace Timeout with RestAPI call
        const applicationsMask = offline_rome;
        const apps = loadApplications(applicationsMask);
        this.setState({apps, applicationKeys: Object.keys(apps)},
            () => this.updateApplicationObjects()
        );
    }

    updateApplicationObjects = () => {
        this.updateReadyState(false);
        for (const appName of this.state.applicationKeys) {
            for (const i in this.state.apps[appName].applicationObjects) {
                this.state.apps[appName].applicationObjects[i].updateObjects(
                        this.props.authData, this.props.accountId).then(() => {
                    this.setState({apps: this.state.apps, 
                                   applicationKeys: this.state.applicationKeys, 
                                   ready: this.state.ready});
                });
            } 
        }
        this.updateReadyState(true);
    }

    clearApplicationItems = () => {
        this.setState({
            applicationKeys: [],
            apps: {},
            ready: false
        });
    }

    componentDidUpdate(prevProps, prevState) {
        // authState or accountId changed
        if ((this.props.accountId != prevProps.accountId) ||
            (this.props.authState != prevProps.authState)) {
                // if signedIn and account present -> (re-) load data
                if ((this.props.authState == 'signedIn') && this.props.accountId ) {
                    console.log("Update Applications");
                    this.updateApplications();

                // if not signedIn or no account -> clear data
                } else {
                    console.log("Clear Applications");
                    this.clearApplicationItems();
                }
        }
    }

    render() {
        const child_element = React.cloneElement(
            this.props.children, 
            { 
                authState: this.props.authState, 
                authData: this.props.authData,
                accountId: this.props.accountId,
                applications: this.state,
            }
        );
        const { apps, applicationKeys, ready } = this.state;
        return (
            <ApplicationsContext.Provider
                value={{
                    apps,
                    applicationKeys,
                    ready,
                    updateApplications: this.updateApplications
                }}
            >
                {child_element}
            </ApplicationsContext.Provider>
        )
    }
}

export default ApplicationsContext;
export { ApplicationsProvider }