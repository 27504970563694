import React from "react"
import Amplify, {Auth} from "aws-amplify"
import { AuthenticatorProvider } from "./src/components/amplify"
import { AccountsProvider } from "./src/context/AccountsContext"
import { ApplicationsProvider } from "./src/context/ApplicationsPreload"

const amplify_configuraiton = require('./config/amplify');


export const wrapPageElement = ({ element, props }) => {

 /*
  if (typeof(options.doNotAuthenticate) == "Array" && options.doNotAuthenticate.includes(props.path)) {
    return element
  }
  */
  
  Amplify.configure(amplify_configuraiton);
  

  return (
    
      <AuthenticatorProvider>
        <AccountsProvider>
          <ApplicationsProvider>
            {element}
          </ApplicationsProvider>
        </AccountsProvider>
      </AuthenticatorProvider>
  );
}