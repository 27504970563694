module.exports = {
    region: 'eu-central-1',
    userPoolId: 'eu-central-1_LbsMH2fJw',
    userPoolWebClientId: '7i6mgimuijmpbec8ic0lt6o26i',
    Auth: {
        scope: ['email'],
        redirectSignIn: `http://localhost:8000/`,
        redirectSignOut: `http://localhost:8000/`,
        responseType: 'code'
    }
}