import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import { TextField, Typography, FormControl, Select, MenuItem, InputLabel } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Status } from 'context/RestAPI';
import { ActionTask } from 'components/Actions';
import { AttributeType } from 'models/Attributes.js';
import styles from './styles';

const NEW_VALUE_ID = "attribute_updater_new_value";
const NEW_DICT_KEY_ID = "attribute_updater_dict_key";
const NEW_DICT_VALUE_ID = "attribute_updater_dict_value";


class UpdateAttribute {
    constructor(attribute, field, action) {
        // todo: asserts (action in Update, Add, Remove)
        this.attribute = attribute;
        this.field = field;
        this.action = action;
    }

    getUpdateMode = () => {
        switch (this.action) {
            case ActionTask.Update:
                return 'update';
            case ActionTask.Add:
                return 'add';
            case ActionTask.Remove:
                return 'remove';
        }
    }
}


// Create title from DialogSelect
function generateDialogTitle(action, attributeName) {
    switch (action) {
        case ActionTask.Update:
            return `Update ${attributeName}`;
        case ActionTask.Add:
            return `Add ${attributeName}`;
        case ActionTask.Remove:
            return `Remove ${attributeName}`;
        default:
            throw new Error(`No title specified for dialog ${dialogSelect}`);
    }
}

function UpdateDialog({classes, applications, applicationObject, updateAttribute, status, feedback, onUpdate, onClose}) {
    if (updateAttribute.action == null) { return (<></>); }
    const [newValue, setNewValue] = useState("");

    const onSelect = (event) => {
        setNewValue(event.target.value);
    }

    const onDictInput = (event) => {
        const key = document.getElementById(NEW_DICT_KEY_ID).value;
        const value = document.getElementById(NEW_DICT_VALUE_ID).value;
        setNewValue({[key]: value});
    }

    const onUpdateInput = (event) => {
        setNewValue(document.getElementById(NEW_VALUE_ID).value);
    }

    const concatinateOptions = (updateAttribute) => {
        if (updateAttribute?.attribute?.options) {
            return updateAttribute.attribute.options;
        }
        if (updateAttribute?.attribute?.applicationObject) {
            const {application, objectsName} = updateAttribute.attribute.applicationObject;
            if (applications?.apps[application] == undefined) {
                return null; // Loading
            }
            const applicationObjects = applications.apps[application].applicationObjects.find(
                appObjs => (appObjs.objectsName == objectsName));
            
            return applicationObjects.list.map(appObj => appObj.id);
        }
        return null; 
    }
    const options = concatinateOptions(updateAttribute);

    return (
        <Dialog 
            open={(updateAttribute != null)} 
            onClose={onClose} 
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{generateDialogTitle(updateAttribute.action, updateAttribute?.attribute?.name)}</DialogTitle>
            <DialogContent>
                {(updateAttribute.action == ActionTask.Update) ? (
                    <>
                    <DialogContentText>
                        You are changing the {updateAttribute?.attribute?.name} of {applicationObject.name} {updateAttribute?.attribute ? applicationObject.getTitle() : ''}.<br/>
                        {updateAttribute?.attribute?.value ? 
                            <>
                            Current Value: <Typography className={classes.attributeText} component={'span'}>{updateAttribute.field.getDisplayValue()}</Typography>
                            </> : null }
                    </DialogContentText>
                    <TextField
                        error={status == Status.Error}
                        autoFocus
                        margin="dense"
                        id={NEW_VALUE_ID}
                        label={`New ${updateAttribute?.attribute?.name}`}
                        type="text"
                        helperText={feedback}
                        variant="outlined"
                        fullWidth
                        onChange={onUpdateInput}
                    />
                    </>
                ) : null }

                {(updateAttribute.action == ActionTask.Add) ? (
                    ((updateAttribute.attribute.type == AttributeType.KeyValueList) ? (
                        <>
                        <DialogContentText>
                            You are adding a new key-value pair to the {updateAttribute?.attribute?.name}:
                        </DialogContentText>
                        <TextField
                            error={status == Status.Error}
                            autoFocus
                            margin="dense"
                            id={NEW_DICT_KEY_ID}
                            label={'Key'}
                            type="text"
                            variant="outlined"
                            fullWidth
                            onChange={onDictInput}
                        />
                        <TextField
                            error={status == Status.Error}
                            margin="dense"
                            id={NEW_DICT_VALUE_ID}
                            label={'Value'}
                            type="text"
                            helperText={feedback}
                            variant="outlined"
                            fullWidth
                            onChange={onDictInput}
                        />
                        </>
                    ) : (
                        <>
                        <DialogContentText>
                            You are adding a new {updateAttribute?.attribute?.name} to the {applicationObject.name} {updateAttribute?.attribute ? applicationObject.getTitle() : ''}:<br/>
                        </DialogContentText>
                        {(options) ? (
                            <FormControl fullWidth className={classes.formControl}>
                                <InputLabel id={`${NEW_VALUE_ID}_label`}>{`Select ${updateAttribute?.attribute?.name}`}</InputLabel>
                                <Select
                                    error={status == Status.Error}
                                    autoFocus
                                    labelId={`${NEW_VALUE_ID}_label`}
                                    id={NEW_VALUE_ID}
                                    value={newValue}
                                    onChange={onSelect}
                                >
                                    {(options.map((option) => (
                                        <MenuItem key={`menu_item_${option}`} value={option}>{option}</MenuItem>
                                    )))}
                                </Select>
                            </FormControl>
                        ) : (
                            <TextField
                                error={status == Status.Error}
                                autoFocus
                                margin="dense"
                                id={NEW_VALUE_ID}
                                label={`New ${updateAttribute?.attribute?.name}`}
                                type="text"
                                helperText={feedback}
                                variant="outlined"
                                onChange={onUpdateInput}
                                fullWidth
                            /> 
                        )}
                        </>
                    ))
                ) : null }
                
                {(updateAttribute.action == ActionTask.Remove) ? (
                    ((updateAttribute.attribute.type == AttributeType.KeyValueList) ? (
                        <DialogContentText>
                            You are removing the following pair from the {updateAttribute?.attribute?.name}:<br/>
                            <Typography className={classes.attributeText} component={'span'}>{updateAttribute.field.getDisplayValue()}</Typography>
                            <br/>
                            Please confirm your intent.
                        </DialogContentText>
                    ) : (
                        <DialogContentText>
                            You are removing the following {updateAttribute?.attribute?.name}:<br/>
                            <Typography className={classes.attributeText} component={'span'}>{updateAttribute.field.getDisplayValue()}</Typography>
                            <br/>
                            Please confirm your intent.
                        </DialogContentText>
                    ))
                ) : null }

            </DialogContent>
            <DialogActions>
                {(status == Status.Loading) ? (
                    <div className={classes.progressspinner}>
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    {(updateAttribute.action == ActionTask.Update) ? (
                        <Button 
                            onClick={onUpdate(newValue)} 
                            color="primary">
                            Change
                        </Button>
                    ) : null }
                    {(updateAttribute.action == ActionTask.Remove) ? (
                        <Button 
                            onClick={onUpdate(updateAttribute.field.getRawValue())} 
                            className={classes.removeButton}>
                            Remove
                        </Button>
                    ) : null }
                    {(updateAttribute.action == ActionTask.Add) ? (
                        <Button 
                            onClick={onUpdate(newValue)} 
                            color="primary">
                            Add
                        </Button>
                    ) : null }
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}

function AttributeUpdater({ classes, applications, applicationObjects, applicationObject, updateAttribute, authData }) {
    const [status, setStatus] = useState(Status.Init);
    const [feedback, setFeedback] = useState("");

    const onClose = (event) => {
        setFeedback("");
        setStatus(Status.Init);
        applicationObject.unselectField();
    }

    const onUpdate = (newValue) => (event) => {
        const operation = updateAttribute.getUpdateMode();
        const updateValue = (updateAttribute.attribute.type == AttributeType.OptionList) ? [newValue] : newValue;
        applicationObjects.updateObject(authData, applicationObject, updateAttribute.attribute, updateValue, operation).then(
            ({status}) => {
                switch (status) {
                    case Status.Init:
                    case Status.Success:
                        break;
                    case Status.Error:
                    case Status.Empty:
                        setFeedback("Invalid Value!");
                        setStatus(status);
                        break;
                    case Status.Unauthorized:
                        setFeedback("Authentication Error!");
                        setStatus(status);
                }
                
            }
        );
    }

    return (
        <UpdateDialog 
            classes={classes}
            applications={applications}
            applicationObject={applicationObject}
            updateAttribute={updateAttribute}
            status={status}
            feedback={feedback}
            onUpdate={onUpdate}
            onClose={onClose}
        />
    );
}

AttributeUpdater.propTypes = {
    classes: PropTypes.object.isRequired,
    applications: PropTypes.object.isRequired,
    applicationObjects: PropTypes.object.isRequired,
    applicationObject: PropTypes.object.isRequired,
    updateAttribute: PropTypes.instanceOf(UpdateAttribute),
    authData: PropTypes.object.isRequired
}

export default withStyles(styles)(AttributeUpdater);
export { UpdateAttribute }