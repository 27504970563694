import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { Button, ButtonGroup } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LinkIcon from '@material-ui/icons/Link';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CropFreeIcon from '@material-ui/icons/CropFree';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

const Task = {
    Details: 'details',
    Preview: 'preview',
    Link: 'link',
    Delete: 'delete',
    Update: 'update',
    Add: 'add',
    Remove: 'remove',
    Copy: 'copy',
    ToCodes: 'toCodes'
}

const Type = {
    Icon: 'icon',
    Text: 'text'
}

function ActionIcon({ actionType, classes, fontSize }) {
    switch (actionType) {
        case Task.Details:
            return (<SettingsIcon fontSize={fontSize} />);
        case Task.Preview:
            return (<VisibilityIcon fontSize={fontSize} />);
        case Task.Link:
            return (<LinkIcon fontSize={fontSize} />);
        case Task.Update:
            return (<SettingsIcon fontSize={fontSize} />);
        case Task.Copy:
            return (<FileCopyIcon fontSize={fontSize} />);
        case Task.ToCodes:
            return (<CropFreeIcon fontSize={fontSize} />);
        case Task.Add:
            return (<AddCircleIcon className={classes.addButton} fontSize={fontSize} />);
        case Task.Remove:
            return (<RemoveCircleIcon className={classes.removeButton} fontSize={fontSize} />);
        default:
            return (<SettingsIcon fontSize={fontSize} />);
    }
}

function ActionText({ classes, action, item }) {
    switch (action.type) {
        case Task.Delete:
            return (
                <Button className={classes.deleteButton} variant="outlined" onClick={action.onClick(item, action.type)}>
                    {"Delete"}
                </Button>
            );
        case Task.Link:
            return (
                <Button variant="outlined" onClick={action.onClick(item, action.type)}>
                    {"Link"}
                </Button>
            );
        case Task.ToCodes:
            return (
                <Button variant="outlined" onClick={action.onClick(item, action.type)}>
                    {"Connected Codes"}
                </Button>
            );
        default:
            return null;
    }
}

function Actions({ classes, actions, item, fontSize='default', type=Type.Icon }) {
    if (type == Type.Icon) {
        return (
            <ButtonGroup key='actions_list' variant="text">
                {actions.map((action, index) => ( 
                    <Button key={`action_${index}`} edge="end" onClick={action.onClick(item, action.type)}>
                        <ActionIcon actionType={action.type} classes={classes} fontSize={fontSize} />
                    </Button>
                ))}
            </ButtonGroup>
        );
    }

    if (type == Type.Text) {
        return (
            <ButtonGroup key='actions_list' variant="outlined">
                {actions.map((action, index) => 
                    <ActionText key={`action_${index}`} classes={classes} action={action} item={item}/>
                )}
            </ButtonGroup>
        );
    }
}

Actions.propTypes = {
	classes: PropTypes.object.isRequired,
    actions: PropTypes.array.isRequired,
    item: PropTypes.object.isRequired
}

export default withStyles(styles)(Actions);
export { Task as ActionTask, Type as ActionType };