import Field from 'models/Fields.js';
import { ActionTask } from 'components/Actions';

const AttributeType = {
    String: 'string',
    Boolean: 'boolean',
    OptionString: 'option_string',
    OptionList: 'option_list',
    KeyValueList: 'key_value_list',
}

class Attribute {
    constructor(name, path, value, type, allowUpdate, visible, options=[], applicationObject=null) {
        // todo asserts
        this.name = name;
        this.path = path;
        this.value = value;
        this.type = type;
        this.allowUpdate = allowUpdate;
        this.visible = visible;
        this.options = options;
        this.applicationObject = applicationObject;
        this.fields = this._getFields();
    }

    getActions = (onUpdate) => (field) => {
        if (!this.allowUpdate) {
            return [];
        }
        switch (this.type) {
            case AttributeType.String:
            case AttributeType.Boolean:
                return [{ type: ActionTask.Update, onClick: onUpdate }];
            case AttributeType.OptionString:
                if (field.data) {
                    return [{ type: ActionTask.Update, onClick: onUpdate },
                            { type: ActionTask.Remove, onClick: onUpdate }];
                } 
                return [{ type: ActionTask.Add, onClick: onUpdate }];
            case AttributeType.OptionList:
                if (field.data) {
                    return [{ type: ActionTask.Remove, onClick: onUpdate }];
                } 
                if (this.options?.length != 0) {
                    return [{ type: ActionTask.Add, onClick: onUpdate }];
                }
                return [];
            case AttributeType.KeyValueList:
                if (field.data) {
                    return [{ type: ActionTask.Remove, onClick: onUpdate }];
                } 
                return [{ type: ActionTask.Add, onClick: onUpdate }];
        }
    }

    _getFields = () => {
        let fields = [];
        switch (this.type) {
            case AttributeType.String:
            case AttributeType.Boolean:
            case AttributeType.OptionString:
                const test_field = new Field(this.value, this.type);
                return [test_field];
            case AttributeType.OptionList:
                for (const listItem of this.value) {
                    fields.push(new Field(listItem, this.type));
                }
                if ((this.allowUpdate && this.options?.length > 0) || (this.value.length == 0)) {
                    // Empty field, so that 
                    // todo: add options to field
                    fields.push(new Field(null, this.type));
                }
                return fields;
            case AttributeType.KeyValueList:
                if (this.value) {
                    for (const key of Object.keys(this.value)) {
                        fields.push(new Field({key, value: this.value[key]}, this.type));
                    }
                }
                
                if (this.allowUpdate) {
                    // Empty field so that key-value pair can be added
                    fields.push(new Field(null, this.type));
                }
                return fields;

            default:
                break;
        }
    }

    _filterOptions = (option) => {
        // Filter out options which are already in values
        return (this.value.indexOf(option) >= 0)
        
    }
}

export default Attribute;
export { AttributeType };