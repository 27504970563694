import React, { createContext } from 'react';
import RestAPI, { Status } from 'context/RestAPI';


const defaultState = {
    selectedAccount: null,
    accounts: {},
    status: Status.Init,
    updateAccounts: () => {},
    setSelectedAccount: () => {}
}

const AccountsContext = createContext(defaultState);

class AccountsProvider extends React.Component {

    state = {
        selectedAccount: null,
        accounts: {},
        status: Status.Init
    }

    updateStatus = (status) => {
        let new_state = Object.assign({}, this.state);
        new_state.status = status;
        this.setState(new_state);
    }

    updateData = (accounts) => {
        if (!accounts || accounts.length < 1) {
            this.setState({
                selectedAccount: null,
                accounts: {},
                status: Status.Empty
            });
            return;
        }

        let processed_data = {};
        for (let account of accounts) {
            processed_data[account.id] = account;
        }
        
        this.setState({
            selectedAccount: Object.keys(processed_data)[0],
            accounts: processed_data,
            status: Status.Success
        });
    }
    
    updateAccounts = async () => {
        this.updateStatus(Status.Loading);
        RestAPI.get('accounts', this.props.authData, this.updateData).then(
            status => this.updateStatus(status)
        );
    }

    setSelectedAccount = (selectedAccount) => {
        if (Object.keys(this.state.accounts).indexOf(selectedAccount) < 0) {
            throw new Error("Unknown Account");
        }
        this.setState({
            selectedAccount,
            accounts: this.state.accounts,
            status: this.state.status
        });
    }

    componentDidMount() {
        this.updateAccounts();
    }

    render() {
        const child_element = React.cloneElement(
            this.props.children, 
            { 
                authState: this.props.authState, 
                authData: this.props.authData,
                accountId: this.state.selectedAccount
            }
        );
        const { accounts, selectedAccount, status } = this.state;
        return (
            <AccountsContext.Provider
                value={{
                    accounts, 
                    selectedAccount,
                    status,
                    setSelectedAccount: this.setSelectedAccount
                }}
            >
                {child_element}
            </AccountsContext.Provider>
        )
    }
}

export default AccountsContext;
export { AccountsProvider }