/*
   Application (e.g. rome)
     |
      -- Object (e.g. codes, products, ...)
          |
           -- Attributes (e.g. id, metrics, ...)
               |
                -- Fields (e.g. UserAgent, HashedIP, Time, meter_9RpYTestGG)

*/

import { AttributeType } from 'models/Attributes.js';

class Field {
    constructor(data, type) {
        // todo asserts
        this.data = data;
        this.type = type;
    }

    getDisplayValue = () => {
        switch (this.type) {
            case AttributeType.String:
            case AttributeType.OptionString:
            case AttributeType.OptionList:
                return (this.data) ? String(this.data) : "";
            case AttributeType.Boolean:
                return String(this.data);
            case AttributeType.KeyValueList:
                return (this.data) ? `${this.data.key}: ${this.data.value}`: "";
        }
    }

    getRawValue = () => {
        switch (this.type) {
            case AttributeType.String:
            case AttributeType.Boolean:
            case AttributeType.OptionString:
            case AttributeType.OptionList:
                return this.data;
            case AttributeType.KeyValueList:
                if (this.data) {
                    return {[this.data.key]: this.data.value};
                }
                return {};
        }
    }
}

export default Field;