'use strict';

import { green, red } from '@material-ui/core/colors';

const styles = theme => ({
	progressspinner: {
		width: '100%',
		height: '50%',
        paddingRight: '24px',
		textAlign: 'right',
	},
	attributeText: {
		fontWeight: 'bold',
		textAlign: 'center'
	},
	removeButton: {
		color: red[500],
	},
	addButton: {
		color: green[500],
	}, 
	formControl: {
		margin: theme.spacing(1),
	}
});

export default styles;