import { UpdateAttribute } from 'components/AttributeUpdater';

class ApplicationObject {
    // E.g. Product, <Icon/>, 'prod_123', [Attribute]
    constructor(name, title, subtitle, icon, id, attributes, rawData) {
        this.name = name;
        this.title = title;
        this.subtitle = subtitle;
        this.icon = icon;
        this.id = id;
        this.attributes = attributes;
        this._rawData = rawData;
        this.updateAttribute = null;
        this._onSelect = null;
    }

    getTitle() {
        return this.title;
    }

    getSubtitle() {
        return this.subtitle;
    }

    getFieldByPath = (path) => {
        return this.attributes.find((attribute) => {
            return attribute.path == path;
        });
    }

    getRawData = (path=null) => {
        if (!path) {
            return this._rawData;
        }
        let data = this._rawData;
        for (let p in path.split('.')) {
            data = data[p];
        }
        return data;
    }

    setOnSelect = (onSelect) => {
        this._onSelect = onSelect;
    }

    selectField = (attribute) => (field, action) => (event)  => {
        const updateAttribute = new UpdateAttribute(attribute, field, action);
        this.updateAttribute = updateAttribute;
        if (this._onSelect) {
            this._onSelect(updateAttribute);
        }
    }

    unselectField = () => {
        this.selectField(null)(null, null)(null);
    }
}


export default ApplicationObject;