const Status = {
	Init: 'init',
	Empty: 'empty',
	Loading: 'loading',
	Success: 'success',
    Unauthorized: 'unauthorized',
	Error: 'error',
}

const Methods = {
    Get: 'GET',
    Post: 'POST'
}

const API_URI = "https://rome.routineslab.com/api_v0/";

const _request = (url, method, authData, setObject, body=null) => {
    let token = authData?.signInUserSession.idToken.jwtToken;
    let headers = { 'Authorization': token };
    if (method == Methods.Post) {
        headers['Content-Type'] = 'application/json'
    }
    
    return fetch(url, {
        method,
        crossDomain: true,
        headers,
        body
    }).then(res => {
        return new Promise(async (resolve, reject) => {
            let data = null;
            let status = Status.Error;
            switch (res.status) {
                case 200:
                    data = await res.json();
                    status = (data) ? Status.Success : Status.Empty
                    resolve({status, data});
                    break;
                case 401:
                    status = Status.Unauthorized;
                    reject({status, error: null});
                    break;
                case 404:
                    status = Status.Empty;
                    resolve({status, data});
                    break;
                case 422:
                    data = await res.json();
                    reject({status, error: data});
                    break;
                default:
                    reject({status, error: res});
                    break;
            }
        });
    }).then(obj => {
        if (setObject) {
            setObject(obj.data);
            return obj.status;
        }
        return { status: obj.status, data: obj.data };
    }).catch(obj => {
        if (setObject) {
            return obj.status;
        } else {
            return { status: obj.status, error: obj.error };
        }
    });
}

const _fillPath = (path, parameters) => {
    for (let parameter of Object.keys(parameters)) {
        path = path.replace(`{${parameter}}`, parameters[parameter]);
    }
    return path;
}

const RestAPI = {
    get: (path, authData, setObject=null, accountId=null, parameters={}) => {
        path = _fillPath(path, parameters);
        let url = API_URI + path;
        if (accountId) {
            url += `?account=${accountId}`;
        }
    
        return _request(url, Methods.Get, authData, setObject, null);
    },

    post: (path, authData, data, setObject=null, parameters={}) => {
        path = _fillPath(path, parameters);
        let url = API_URI + path;
        let body = JSON.stringify(data);

        return _request(url, Methods.Post, authData, setObject, body);
    }
}

export { Status };
export default RestAPI;